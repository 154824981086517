// Modal

.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  z-index: 1060 !important;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch !important;
  background: rgba($black, .8) !important;
}

.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0 !important;
  background: transparent;
  border: 0 !important;
  padding: 0 !important;
  outline: none;
  transform: translateY(-30%);
  transition: all 500ms ease;
  width: 100% !important;
  height: 100% !important;
  opacity: 0;

  &--after-open {
    transform: translateY(0);
    opacity: 1;
  }

  &--before-close {
    transform: translateY(-30%);
  }

  .modal-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .modal-body {
      overflow: hidden;
      will-change: transform;
      height: 100%;

      .modal-content {
        width: 100%;
        height: 100%;
      }
    }
  }

  .close {
    display: none;
  }

  // Bracket
  &.modal-theme-bracket {
    width: px(976) !important;
    height: px(565) !important;

    .modal-wrapper {
      .modal-body {
        position: relative;
        background: url('#{$cdn}/img/bg-popup-bracket.png') 0 0/100% 100% no-repeat;
      }
    }
  }

  // Search
  &.modal-theme-search {
    width: px(859) !important;
    height: px(565) !important;

    .modal-wrapper {
      .modal-body {
        position: relative;
        background: url('#{$cdn}/img/bg-popup-search.png') 0 0/100% 100% no-repeat;
      }
    }
  }

  // Coin + Select
  &.modal-theme-coin, &.modal-theme-select {
    width: px(775) !important;
    height: px(565) !important;

    .modal-wrapper {
      .modal-body {
        position: relative;
        background: url('#{$cdn}/img/bg-popup-coin.png') 0 0/100% 100% no-repeat;
      }
    }
  }

  // Exchange
  &.modal-theme-exchange {
    width: px(703) !important;
    height: px(470) !important;

    .modal-wrapper {
      .modal-body {
        position: relative;
        background: url('#{$cdn}/img/bg-popup-exchange.png') 0 0/100% 100% no-repeat;
      }
    }
  }

  // History + Rules + Prizes
  &.modal-theme-history, &.modal-theme-rules, &.modal-theme-prizes {
    width: px(1153) !important;
    height: px(826) !important;

    .modal-wrapper {
      .modal-body {
        position: relative;
        background: url('#{$cdn}/img/bg-popup-history.png') 0 0/100% 100% no-repeat;
      }
    }
  }

  // Guide
  &.modal-theme-guide {
    width: px(1153) !important;
    height: px(826) !important;

    .modal-wrapper {
      .modal-body {
        position: relative;
        background: url('https://cdn.vn.garenanow.com//fo3vn/project/playerPack/HuongDan11mT11.png') 0 0/100% 100% no-repeat;
      }
    }
  }
}
