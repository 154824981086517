// Footer

.footer {
  background: #000;

  &__wrap {
    width: px(1278);
    height: px(186);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    font-size: px(11);
    font-family: $font-roboto;
    text-transform: uppercase;
    color: rgba(#fff, .3);
    margin: 0 px(24) 0 px(29);
    text-align: justify;
  }

  &__left {
    img {
      width: px(204);
    }
  }

  &__right {
    img {
      width: px(133);
    }
  }
}
