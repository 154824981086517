// History reward

.history-reward {
  padding-right: px(25);

  table {
    width: 100%;
    color: $colorGreen9;
    text-align: center;
    text-transform: uppercase;

    th {
      font-size: px(26);
      height: px(58);
      font-family: $font-bebas;
      font-weight: normal;
      text-transform: uppercase;
      border-top: 1px solid rgba($colorGreen6, .4);
      border-bottom: 1px solid rgba($colorGreen6, .4);

      &:nth-child(2) {
        width: 44%;
      }
    }

    td {
      padding: px(7) px(5);
      font-size: px(20);
      height: px(61);
      font-family: $font-roboto;
      border-bottom: 1px solid rgba($colorGreen6, .4);
    }
  }
}
