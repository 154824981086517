// Home

.home {
  width: px(1920);
  height: px(3214);
  overflow: hidden;
  position: relative;
  background: url('#{$cdn}/img/background.jpg') 0 0/100% 100% no-repeat;

  &>.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__prize {
    position: absolute;
    top: px(428);
    user-select: none;
    pointer-events: none;
    z-index: 1;

    &--left {
      left: px(188);
      height: px(177);
    }

    &--right {
      right: px(195);
      height: px(176);
    }
  }
}
