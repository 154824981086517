// Popup coin

.modal-content {
  .popup-coin {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: px(60);

    &__title {
      font-size: px(53.28);
      margin-bottom: px(12);
      @include textDecor(#00680a, #00b511);
    }

    &__desc {
      font-size: px(30);
      color: $colorGreen3;
      text-transform: uppercase;
      margin-bottom: px(40);
    }

    &__result {
      display: flex;
      align-items: center;
      justify-content: center;

      &--shooter, &--keeper {
        width: px(235);
        height: px(235);
        margin: 0 px(25);
        font-size: px(18);
        padding-top: px(137);
      }

      &--shooter {
        color: $colorBrown5;
        text-shadow: 0 0 px(5) $colorBrown6;
        background: url('#{$cdn}/img/icon-coin-shooter.png') 0 0/100% 100% no-repeat;
      }

      &--keeper {
        color: $colorGray2;
        text-shadow: 0 0 px(5) $colorGray3;
        background: url('#{$cdn}/img/icon-coin-keeper.png') 0 0/100% 100% no-repeat;
      }

      .lucky__coin {
        position: relative;
        margin: 0 auto;
        width: px(235);
        height: px(235);
        transition: -webkit-transform 1.5s ease-in;
        -webkit-transform-style: preserve-3d;

        .side-a, .side-b {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          box-shadow: inset 0 0 45px rgba(255,255,255,.3), 0 12px 20px -10px rgba(0,0,0,.4);
          position: absolute;
          -webkit-backface-visibility: hidden;
        }

        .side-a {
          z-index: 100;
          background: url('#{$cdn}/img/icon-coin-shooter.png') 0 0/100% 100% no-repeat;
        }

        .side-b {
          transform: rotateY(-180deg);
          background: url('#{$cdn}/img/icon-coin-keeper.png') 0 0/100% 100% no-repeat;
        }

        &.heads {
          animation: flipHeads 1.5s ease-out forwards;
        }

        &.tails {
          animation: flipTails 1.5s ease-out forwards;
        }
      }

      @keyframes flipHeads {
        from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
        to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
      }

      @keyframes flipTails {
        from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
        to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
      }
    }
  }
}
