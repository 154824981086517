// Popup select

.modal-content {
  .popup-select {
    text-align: center;
    padding: px(50) px(50) 0;

    &__title {
      font-size: px(53.28);
      font-family: $font-sd;
      color: $colorGreen;
    }

    &__desc {
      font-size: px(30);
      color: $colorGreen3;
      text-transform: uppercase;
      margin-bottom: px(40);
    }

    &__items {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;

      li {
        width: 45%;
      }
    }

    &__item {
      $self: '.popup-select__item';

      width: 100%;
      display: block;
      color: $colorGreen3 !important;

      &--image {
        img {
          height: px(164);
        }
      }

      &--name {
        font-size: px(18);
        font-weight: 700;
        margin-top: px(14);
      }

      &--note {
        font-size: px(18);
        color: $colorRed;
      }

      &.claimed {
        cursor: default;

        #{$self}--image {
          position: relative;

          img {
            filter: grayscale(50%) brightness(50%);
          }

          &:before {
            content: '';
            width: px(125);
            height: px(92);
            background: url('#{$cdn}/img/icon-claimed.png') 0 0/100% 100% no-repeat;
            position: absolute;
            top: calc(50% - #{px(92/2)});
            left: calc(50% - #{px(125/2)});
            z-index: 1;
            user-select: none;
            pointer-events: none;
          }
        }
      }
    }
  }
}
