// Milestones

.milestones {
  width: px(1382);
  height: px(949);
  position: absolute;
  top: px(2201);
  left: calc(50% - #{px(1382/2)});
  text-align: center;
  text-transform: uppercase;
  color: $colorGreen;
  padding: px(38) px(30) 0;
  background: url('#{$cdn}/img/bg-milestones.png') 0 0/100% 100% no-repeat;

  &__title {
    width: px(529);
    position: absolute;
    bottom: calc(100% + #{px(21)});
    left: calc(50% - #{px(529/2)});
  }

  &__desc {
    font-size: px(20);
    line-height: 1.3em;
  }

  &__point {
    font-size: px(30);
  }

  &__main {
    width: 100%;
    height: px(750);
    margin: px(10) auto 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      padding: px(20);
    }
  }

  .milestone {
    $self: '.milestone';
    position: relative;
    display: inline-block;

    &__image {
      position: relative;

      img {
        height: px(151);

        & ~ img {
          margin-left: px(36);
        }
      }
    }

    &__point {
      color: $colorBlue2;
      font-size: px(22.33);
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: px(60);
      padding-top: px(5);
      margin: 0 auto;
      text-shadow: 0 0 px(4) $white;
    }

    &:not(.is-select) {
      cursor: default;

      #{$self}__once {
        width: px(166);
        height: px(56);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: px(18);
        line-height: 1.1em;
        padding: px(5) px(20) 0;
        position: absolute;
        bottom: calc(100% + #{px(11)});
        left: calc(50% - #{px(166/2)});
        background: url('#{$cdn}/img/bg-note.png') 0 0/100% 100% no-repeat;
      }

      #{$self}__point {
        width: px(164);
        background: url('#{$cdn}/img/bg-milestone-point.png') 0 0/100% 100% no-repeat;
      }

      &.claimed {
        #{$self}__image {
          &:before {
            content: '';
            width: px(125);
            height: px(92);
            display: block;
            position: absolute;
            top: calc(50% - #{px(92/2)});
            left: calc(50% - #{px(125/2)});
            z-index: 1;
            user-select: none;
            pointer-events: none;
            background: url('#{$cdn}/img/icon-claimed.png') 0 0/100% 100% no-repeat;
          }

          img {
            filter: brightness(0.4) grayscale(0.5);
          }
        }
      }
    }

    &.is-select {
      #{$self}__turn {
        width: 100%;
        text-transform: uppercase;
        font-size: px(17);
        color: $colorBrown;
        position: absolute;
        bottom: 100%;
        left: 0;
        text-align: center;
        white-space: nowrap;
      }

      #{$self}__point {
        width: px(433);
        background: url('#{$cdn}/img/bg-milestone-point-1.png') 0 0/100% 100% no-repeat;
      }
    }
  }
}
