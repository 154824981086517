// Animations

@keyframes ballLeftWin {
  0% {
    transform: rotate(0) scale(1);
  }
  80%{
    transform: rotate(75deg) scale(0.6) translateY(px(200)) translateX(px(-700));
  }
  100% {
    transform: rotate(75deg) scale(0.6) translateY(px(200)) translateX(px(-700));
  }
}

@keyframes ballLeftLose {
  0% {
    transform: rotate(0) scale(1);
  }
  60%{
    transform: rotate(75deg) scale(0.6) translateY(px(200)) translateX(px(-700));
  }
  100% {
    transform: scale(0.6) translateY(px(-1200)) translateX(px(-1200));
  }
}

@keyframes ballRightWin {
  0% {
    transform: rotate(0) scale(1);
  }
  80%{
    transform: rotate(-40deg) scale(0.6) translateY(px(-200)) translateX(px(700));
  }
  100% {
    transform: rotate(-40deg) scale(0.6) translateY(px(-200)) translateX(px(700));
  }
}

@keyframes ballRightLose {
  0% {
    transform: rotate(0) scale(1);
  }
  60%{
    transform: rotate(-40deg) scale(0.6) translateY(px(-200)) translateX(px(700));
  }
  100% {
    transform: scale(0.6) translateY(px(-1300)) translateX(px(1100));
  }
}

@keyframes ballCenterWin {
  0% {
    transform: rotate(0) scale(1);
  }
  80%{
    transform: rotate(10deg) scale(0.6) translateY(px(-500));
  }
  100% {
    transform: rotate(10deg) scale(0.6) translateY(px(-500));
  }
}

@keyframes ballCenterLose {
  0% {
    transform: rotate(0) scale(1);
  }
  60%{
    transform: rotate(10deg) scale(0.6) translateY(px(-500));
  }
  100% {
    transform: rotate(10deg) scale(0.6) translateY(px(-1200));
  }
}

@keyframes ballRotate {
  0% {
    transform: rotate(0deg);
    filter: blur(px(3));
  }
  100% {
    transform: rotate(360deg);
    filter: blur(px(3));
  }
}

@keyframes GkJumpLeft {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.9) translate(px(-250), px(-40));
  }
  100% {
    transform: scale(0.9) translate(px(-250), px(-40));
  }
}

@keyframes GkJumpRight {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.9) translate(px(250), px(-40));
  }
  100% {
    transform: scale(0.9) translate(px(250), px(-40));
  }
}

@keyframes GkJumpCenter {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.9) translateY(px(-40));
  }
  100% {
    transform: scale(0.9) translateY(px(-40));
  }
}
