// Sweetalert2

.swal2-container {
  z-index: 1070 !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba($black, .8) !important;
}

.swal2-popup {
  width: px(648) !important;
  height: px(305) !important;
  border-radius: 0 !important;
  padding: 0 px(20) !important;
  background: url('#{$cdn}/img/bg-popup.png') 0 0/100% 100% no-repeat !important;

  &.popup-reward, &.popup-error {
    width: px(470) !important;
    height: px(703) !important;
    background: url('#{$cdn}/img/bg-popup-reward.png') 0 0/100% 100% no-repeat !important;
  }

  .swal2-title {
    display: none !important;
  }

  .swal2-header, .swal2-content {
    padding: 0 !important;
  }

  .swal2-actions {
    margin: px(20) 0 0;
    width: auto;

    .swal2-confirm, .swal2-cancel {
      border: 0 !important;
      margin: 0 px(10) !important;
      padding: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      text-transform: uppercase !important;
      font-size: px(18) !important;
      width: px(124);
      height: px(60);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &.swal2-confirm {
        color: $colorBlue2 !important;
        background: url('#{$cdn}/img/btn-exchange.png') 0 0/100% 100% no-repeat !important;
      }

      &.swal2-cancel {
        color: $colorOrange !important;
        background: url('#{$cdn}/img/btn-cancel.png') 0 0/100% 100% no-repeat !important;
      }
    }
  }

  .swal2-close {
    width: auto;
    height: auto;
    color: transparent;
    outline: none;
    display: none;
    box-shadow: none !important;
  }

  .popup-alert {
    &__message, &__note {
      font-size: px(30);
      line-height: 1.2em;
      color: $colorGreen7 !important;
      text-transform: uppercase;
    }

    &__name {
      font-size: px(22);
      line-height: 1.2em;
      color: $black !important;
      margin-top: px(25);
    }

    &__image {
      text-align: center;
      margin-top: px(28);

      img {
        max-width: 80%;
        max-height: px(450);
      }
    }

    &__rank {
      font-size: px(20);
      text-transform: uppercase;
      color: $colorGreen7 !important;
      margin-top: px(45);

      span {
        font-size: px(58);
        line-height: 1.1em;
        color: $colorGreen !important;
        font-family: $font-sd;
        display: inline-block;
      }
    }

    &__round {
      font-size: px(58);
      line-height: 1.1em;
      color: $colorGreen !important;
      font-family: $font-sd;
      display: inline-block;
      margin: px(10) 0 px(20);
    }

    &__point {
      width: px(353);
      height: px(52);
      font-size: px(22);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: $colorGreen4;
      color: $white !important;
      text-transform: uppercase;
      margin: px(31) auto 0;
      border-radius: px(10);
      border: px(1) solid $colorGreen5;
    }
  }

  &.popup-no-title {
    .swal2-title {
      display: none !important;
    }
  }

  &.popup-success {

  }

  &.popup-error {

  }

  &.popup-confirm {

  }
}
