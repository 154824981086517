// Popup bracket

.modal-content {
  .popup-search {
    width: 100%;
    height: 100%;
    padding-top: px(445);

    &__progress {
      width: px(404);
      height: px(19);
      margin: 0 auto;
      border-radius: px(9);
      background: $colorBrown4;
      border: px(1) solid $colorGray;
      display: flex;
      align-items: center;
      padding: 0 px(3);

      &--value {
        display: block;
        height: px(13);
        border-radius: px(7);
        @include gradientY(#64d843, #5af4d9);
      }
    }

    &__status {
      text-align: center;
      color: $colorBrown2;
      font-size: px(20);
      margin-top: px(5);
    }
  }
}
