// Popup bracket

.modal-content {
  .popup-bracket {
    width: 100%;
    height: 100%;
    position: relative;

    &__prizes {
      position: absolute;
      bottom: px(20);
      left: 50%;
      transform: translateX(-50%);
      text-align: center;

      img {
        height: px(127);
      }

      span {
        display: block;
        color: $colorGreen;
        font-size: px(18);
        white-space: nowrap;
        margin-top: px(4);
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        width: px(122);
        height: px(44);
        font-size: px(18);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-top: px(4);
        text-transform: uppercase;
        position: absolute;
        color: $white;
        background: $colorBrown2;
        border: px(1) solid $colorGray;

        &.disabled {
          color: rgba($white, .5);
          background: $colorBrown3;
        }

        &.is-current {
          &:before {
            content: '';
            display: block;
            width: px(71);
            height: px(46);
            position: absolute;
            bottom: px(35);
            left: calc(50% - #{px(71/2)});
            background: url('#{$cdn}/img/icon-gamepad.png') 0 0/100% 100% no-repeat;
          }
        }

        &[data-id="2"] {
          top: px(320);
          left: px(353);
        }

        &[data-id="3"] {
          top: px(320);
          right: px(353);
        }

        &[data-id="4"] {
          top: px(240);
          left: px(209);
        }

        &[data-id="5"] {
          top: px(409);
          left: px(209)
        }

        &[data-id="6"] {
          top: px(240);
          right: px(209);
        }

        &[data-id="7"] {
          top: px(409);
          right: px(209)
        }

        &[data-id="8"] {
          top: px(200);
          left: px(54);
        }

        &[data-id="9"] {
          top: px(283);
          left: px(54);
        }

        &[data-id="10"] {
          top: px(367);
          left: px(54);
        }

        &[data-id="11"] {
          top: px(450);
          left: px(54);
        }

        &[data-id="12"] {
          top: px(200);
          right: px(54);
        }

        &[data-id="13"] {
          top: px(283);
          right: px(54);
        }

        &[data-id="14"] {
          top: px(367);
          right: px(54);
        }

        &[data-id="15"] {
          top: px(450);
          right: px(54);
        }
      }
    }
  }
}
