// Popup history

.modal-content {
  .popup-history {
    width: 100%;
    height: 100%;
    padding: px(43) px(80) 0;

    &__title {
      text-align: center;

      img {
        height: px(66);
      }
    }

    &__tabs {
      margin: px(17) auto 0;
      padding: 0 px(9);
      list-style: none;
      width: px(355);
      height: px(56);
      border-radius: px(10);
      background: $colorGreen4;
      border: px(1) solid $colorGreen8;
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        a {
          width: px(163);
          height: px(47);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: px(19);
          color: $colorOrange !important;
          text-transform: uppercase;
          filter: grayscale(0.5) brightness(0.7);
          background: url('#{$cdn}/img/btn-tab.png') 0 0/100% 100% no-repeat;

          &.active {
            filter: none;
          }
        }
      }
    }

    &__content {
      height: px(570);
      margin-top: px(20);

      @import 'history-reward';
      @import 'history-game';
    }
  }
}
