// Base

html {
  font-size: (1/$w * 100)+vw;
}

a {
  text-decoration: none !important;
}

* {
  outline: none !important;
}

#main, body {
  overflow-x: hidden;
}

.bg-image {
  background-size: cover;
  background-color: $gray-300;
  background-repeat: no-repeat;
  background-position: center center;
}

.__react_component_tooltip {
  font-size: px(16);
  padding: px(5) px(15);
}
