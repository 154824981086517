// Header

.header {
  position: absolute;
  top: px(13);
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  &__logo {
    width: px(299);
    height: px(70);
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: px(35);

    img {
      height: px(46);
    }
  }

  &__menu, &__user {
    margin: 0 0 0 px(7);
    background: $white;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: px(54);
    border-radius: px(25);
  }

  &__menu {
    padding: 0 px(25);

    li {
      color: $colorBlue;
      font-size: px(17);
      text-transform: uppercase;

      & ~ li {
        &:before {
          content: '|';
          color: $colorBlue;
          margin: 0 px(9);
        }
      }

      a {
        display: inline-block;
        color: inherit !important;
      }
    }
  }

  &__user {
    padding: 0 px(2);

    li {
      & ~ li {
        &:not(:last-child) {
          &:before {
            content: '|';
            color: $colorBlue;
            margin: 0 px(9);
          }
        }
      }

      &:first-child {
        padding-left: px(25);
      }

      &:nth-child(2) {
        margin-right: px(13);
      }

      &:last-child {
        padding: 0 !important;
      }

      span {
        color: $colorBlue;
        font-size: px(17);
        text-transform: uppercase;
      }

      a {
        width: px(160);
        height: px(50);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: px(24);
        text-transform: uppercase;
        color: $white !important;
        padding-top: px(5);
        text-shadow: 0 0 px(3) $black;
        background: url('#{$cdn}/img/btn-login.png') 0 0/100% 100% no-repeat;
      }
    }
  }
}
