// Popup prizes

.modal-content {
  .popup-prizes {
    width: 100%;
    height: 100%;
    padding: px(43) px(120 - 25) 0 px(120);

    &__title {
      text-align: center;
      margin-bottom: px(40);

      img {
        height: px(52);
      }
    }

    &__content {
      height: px(630);
    }

    &__list {
      padding-right: px(25);

      h2 {
        width: 100%;
        height: px(52);
        color: $white;
        font-size: px(22);
        font-weight: normal;
        text-align: center;
        text-transform: uppercase;
        background: $colorGreen4;
        border: px(1) solid $colorGreen5;
        border-radius: px(14);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 px(11);
      }

      ul {
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
          padding: px(11) px(16);
        }
      }
    }

    &__item {
      $self: '.popup-prizes__item';

      text-align: center;

      &--img {
        width: px(142);
        height: px(142);
        border-radius: px(20);
        display: flex;
        align-items: center;
        justify-content: center;
        border: px(6) solid $colorGreen10;

        img {
          max-height: 80%;
        }
      }

      &--name {
        width: px(142);
        font-size: px(15);
        color: $colorGreen9;
        line-height: 1.2em;
        margin-top: px(7);
      }

      &.is-select {
        #{$self}--img, #{$self}--name {
          width: px(313) !important;
        }

        #{$self}--img {
          img {
            margin: 0 px(14);
          }
        }
      }
    }
  }
}
