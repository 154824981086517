// Popup exchange

.modal-content {
  .popup-exchange {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 px(50) 0 px(50);
    text-transform: uppercase;

    &__prize {
      img {
        width: px(209);
      }
    }

    &__info {
      width: px(340);
      font-size: px(22);
      color: $colorGreen7;
    }

    &__name {
      color: $colorGreen;
      font-size: px(32);
      line-height: 1.1em;
      margin-bottom: px(5);
    }

    &__point {
      margin-bottom: px(5);
    }

    &__qty {
      margin-bottom: px(13);

      &--value {
        position: relative;
        width: px(247);
        height: px(52);
        background: $colorGreen4;
        border: px(1) solid $colorGreen5;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: px(4);

        span {
          color: $white;
          font-size: px(30);
        }
      }

      &--prev, &--next {
        display: inline-block;
        width: 0;
        height: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-top: px(10) solid transparent;
        border-bottom: px(10) solid transparent;
      }

      &--prev {
        left: px(16);
        border-right: px(10) solid $white;
      }

      &--next {
        right: px(16);
        border-left: px(10) solid $white;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      margin-top: px(10);

      &--submit, &--cancel {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: px(20);
        padding-top: px(3);
      }

      &--submit {
        width: px(124);
        height: px(60);
        color: $colorBlue2 !important;
        background: url('#{$cdn}/img/btn-exchange.png') 0 0/100% 100% no-repeat;
      }

      &--cancel {
        width: px(125);
        height: px(59);
        margin-left: px(6);
        color: $colorOrange !important;
        background: url('#{$cdn}/img/btn-cancel.png') 0 0/100% 100% no-repeat;
      }
    }
  }
}
