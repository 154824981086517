// Variables

$cdn: 'https://cdn.vn.garenanow.com/web/fo3/fo3/fo4-11m-1121';
$w: 1920;
$h: 1080;

$colorRed: #d70000;
$colorBlue: #0023d7;
$colorBlue2: #050ecd;
$colorBrown: #2f0000;
$colorBrown2: #ab8622;
$colorBrown3: #a99e81;
$colorBrown4: #baa440;
$colorBrown5: #7c3c06;
$colorBrown6: #cd9a35;
$colorGray: #fffed4;
$colorGray2: #5f5f5f;
$colorGray3: #b5b5b5;
$colorGray4: #ececec;
$colorOrange: #cd4b05;
$colorOrange2: #ce4c06;
$colorGreen: #004815;
$colorGreen2: #233000;
$colorGreen3: #222f00;
$colorGreen4: #769c1d;
$colorGreen5: #c0e765;
$colorGreen6: #00b511;
$colorGreen7: #009d0f;
$colorGreen8: #c2e76c;
$colorGreen9: #014a0b;
$colorGreen10: #00ba09;

$font-roboto: 'Roboto', sans-serif;
$font-bebas: 'UTM Bebas', sans-serif;
$font-vl: 'VL GhostKid AOE Pro', sans-serif;
$font-sd: '000 SanDiego2007 [TeddyBear]', sans-serif;

$font-size-base: 20rem;
$font-family-base: $font-vl;

$body-bg: $black;
$body-color: $white;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
  xxxl: 1800px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
  xxxl: 1740px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");
