// History game

.history-game {
  padding-right: px(25);

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    width: px(286);
    height: px(147);
    position: relative;
    margin-bottom: px(42);
    background: url('#{$cdn}/img/bg-history-game.png') 0 0/100% 100% no-repeat;

    &--head {
      width: 100%;
      height: px(51);
      padding: px(7) px(15) 0;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--match {
      color: $white;
      font-size: px(20);
      text-transform: uppercase;
    }

    &--time {
      font-size: px(16);
      color: $colorGreen9;
    }

    &--body {
      width: px(272);
      height: px(90);
      position: absolute;
      top: px(51);
      left: px(7);
      color: $colorGreen9;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: px(18);
      text-align: center;
    }
  }

  &__nodata {

  }
}
