// Fonts

@font-face {
  font-family: 'UTM Bebas';
  src: url('#{$cdn}/fonts/UTMBebas.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VL GhostKid AOE Pro';
  src: url('#{$cdn}/fonts/VLGhostKidAOEPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '000 SanDiego2007 [TeddyBear]';
  src: url('#{$cdn}/fonts/000SanDiego2007TeddyBear.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
