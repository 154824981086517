// Shop

.shop {
  width: px(1204);
  height: px(815);
  position: absolute;
  top: px(1203);
  left: calc(50% - #{px(1204/2)});
  text-align: center;
  text-transform: uppercase;
  color: $colorBrown;
  padding: px(34) px(30) 0;
  background: url('#{$cdn}/img/bg-shop.png') 0 0/100% 100% no-repeat;

  &__title {
    width: px(534);
    position: absolute;
    bottom: calc(100% + #{px(16)});
    left: calc(50% - #{px(534/2)});
  }

  &__note {
    font-size: px(20);
  }

  &__point {
    font-size: px(30);
  }

  &__main {
    width: 100%;
    height: px(660);
    margin: px(10) auto 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      padding: px(10);
    }
  }

  &__item {
    display: inline-block;

    &.disabled {
      filter: grayscale(0.5) brightness(0.5);
    }

    &--image {
      width: px(135);
      height: px(135);
      display: flex;
      align-items: center;
      justify-content: center;
      background: $colorRed;
      border: px(1) solid $colorGray;

      img {
        width: px(121);
      }
    }

    &--point {
      width: px(134);
      height: px(47);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: px(19);
      padding-top: px(4);
      margin-top: px(10);
      color: $colorOrange !important;
      background: url('#{$cdn}/img/bg-shop-point.png') 0 0/100% 100% no-repeat;

      span {
        &:nth-child(2) {
          display: none;
        }
      }

      &:hover {
        span {
          &:nth-child(1) {
            display: none;
          }

          &:nth-child(2) {
            display: inline;
          }
        }
      }
    }
  }
}
