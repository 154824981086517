// Game

.game {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: px(1080);
  z-index: 100;

  &__title {
    width: px(618);
    position: absolute;
    top: px(85);
    left: calc(50% - #{px(618/2)});
  }

  &__mode {
    position: absolute;
    top: px(189);
    left: 0;
    width: 100%;
    text-align: center;

    a {
      display: inline-block;
      filter: grayscale(0.5) brightness(0.7);

      img {
        height: px(79);
      }

      &.active {
        filter: none;
      }

      & ~ a {
        margin-left: px(9);
      }
    }
  }

  &__animation {
    &--gk, &--player, &--ball, &:before, &:after {
      user-select: none;
      pointer-events: none;
      position: absolute;
    }

    &:before {
      content: '';
      display: block;
      width: px(112);
      height: px(21);
      top: px(645);
      left: px(885);
      background: url('#{$cdn}/img/decor-gk.png') 0 0/100% 100% no-repeat;
    }

    &:after {
      content: '';
      display: block;
      width: px(94);
      height: px(12);
      top: px(885);
      left: px(893);
      background: url('#{$cdn}/img/decor-ball.png') 0 0/100% 100% no-repeat;
    }

    &--gk {
      top: px(414);
      left: px(855);
      z-index: 10;

      img {
        height: px(243);
      }

      &[data-side="left"], &[data-side="right"] {
        img {
          height: px(219);
        }
      }

      &[data-side="center"] {
        animation: GkJumpCenter 1100ms ease-in-out;
      }

      &[data-side="left"] {
        animation: GkJumpLeft 1100ms ease-in-out;
      }

      &[data-side="right"] {
        animation: GkJumpRight 1100ms ease-in-out;
      }
    }

    &--player {
      height: px(270);
      top: px(633);
      left: px(670);
      z-index: 10;
    }

    &--ball {
      top: px(795);
      left: px(886);
      z-index: 20;

      img {
        height: px(98);
      }

      &[data-result="win"], &[data-result="lose"] {
        img {
          animation: ballRotate 300ms infinite linear;
        }
      }

      &[data-side="left"][data-result="win"] {
        animation: ballLeftWin 1200ms ease-in-out;
      }

      &[data-side="left"][data-result="lose"] {
        animation: ballLeftLose 1600ms ease-in-out;
      }

      &[data-side="right"][data-result="win"] {
        animation: ballRightWin 1200ms ease-in-out;
      }

      &[data-side="right"][data-result="lose"] {
        animation: ballRightLose 1600ms ease-in-out;
      }

      &[data-side="center"][data-result="win"] {
        animation: ballCenterWin 1200ms ease-in-out;
      }

      &[data-side="center"][data-result="lose"] {
        animation: ballCenterLose 1600ms ease-in-out;
      }
    }
  }

  &__purchase {
    display: flex;
    justify-content: space-between;
    width: px(550);
    position: absolute;
    top: px(744);
    left: px(1100);

    &--type {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        & ~ li {
          margin-top: px(12);
        }

        a {
          display: flex;
          align-items: center;
          font-size: px(20);
          text-transform: uppercase;
          color: $colorGreen2 !important;

          &:before {
            content: '';
            width: px(37);
            height: px(37);
            display: inline-block;
            margin: px(-3) px(7) 0 0;
            background: url('#{$cdn}/img/icon-uncheck.png') 0 0/100% 100% no-repeat;
          }

          &.active {
            &:before {
              background: url('#{$cdn}/img/icon-checked.png') 0 0/100% 100% no-repeat;
            }
          }
        }
      }
    }

    &--turn {
      position: relative;
      margin-top: px(10);

      input {
        display: block;
        width: px(215);
        height: px(52);
        border: px(1) solid $colorGreen5;
        font-size: px(20);
        color: $white;
        padding: 0;
        text-align: center;
        background: $colorGreen4;
        box-shadow: 0 0 px(5) $colorGreen inset;
        @include placeholderColor($white);

        &:disabled {
          filter: grayscale(0.5) brightness(0.7);
        }

        &:focus {
          @include placeholderColor(transparent);
        }
      }

      img {
        height: px(33);
        position: absolute;
        top: px(-10);
        right: px(-13);
      }
    }

    &--time {
      width: px(300);
      font-size: px(20);
      color: $colorGreen3;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.3em;

      span {
        font-size: px(24);
      }
    }

    &--btn {
      width: px(227);
      height: px(80);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      color: $colorOrange2 !important;
      text-transform: uppercase;
      margin: px(10) auto 0;
      background: url('#{$cdn}/img/btn-purchase.png') 0 0/100% 100% no-repeat;

      span {
        line-height: 1em;

        &.note {
          font-family: $font-bebas;
          font-size: px(20);
          line-height: 1em;
          margin-bottom: px(6);
        }

        &.label {
          font-size: px(17);
        }

        &.price {
          font-size: px(30);
        }
      }
    }
  }

  &__playnow {
    width: px(227);
    height: px(80);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colorOrange2 !important;
    text-transform: uppercase;
    padding-top: px(4);
    font-size: px(37);
    background: url('#{$cdn}/img/btn-purchase.png') 0 0/100% 100% no-repeat;
    position: absolute;
    top: px(820);
    left: px(1041);
  }

  .match {
    &__info {
      text-align: center;
      position: absolute;
      top: px(731);
      left: px(260);

      &--title {
        height: px(57);
        display: inline-block;
      }

      &--stats {
        margin: px(17) 0 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          display: flex;
          align-items: center;

          .uid {
            width: px(146);
            height: px(52);
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: px(18);
            border: px(1) solid $colorGreen5;
            background: $colorGreen4;
            box-shadow: 0 0 px(5) $colorGreen inset;
            margin-right: px(10);
          }

          .score {
            font-size: px(30);
            color: $colorGreen3;
          }

          &:nth-child(2) {
            flex-direction: row-reverse;

            &:after {
              content: ':';
              font-size: px(30);
              color: $colorGreen3;
              margin: 0 px(6);
            }

            .uid {
              margin: 0 0 0 px(10);
            }
          }
        }
      }

      &--round {
        width: px(259);
        height: px(52);
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        color: $white;
        font-size: px(18);
        text-transform: uppercase;
        padding-top: px(3);
        margin: px(19) auto 0;
        background: url('#{$cdn}/img/bg-round.png') 0 0/100% 100% no-repeat;
      }
    }

    &__play {
      position: absolute;
      top: px(727);
      right: px(247);
      text-align: center;

      &--title {
        height: px(59);
        display: inline-block;
        margin-bottom: px(11);
      }

      &--choices {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          & ~ li {
            margin-left: px(20);
          }

          a {
            display: inline-block;
            filter: drop-shadow(0 0 px(5) $black);

            img {
              height: px(135);
            }

            &.active {
              opacity: 1 !important;
              filter: drop-shadow(0 0 px(10) $black) !important;
            }
          }
        }

        &.selected li a {
          opacity: 0.7;
          filter: grayscale(0.5) brightness(0.5);
        }
      }
    }

    &__progress {
      position: absolute;
      top: px(790);
      right: px(718);
      text-align: center;
      text-transform: uppercase;

      &--time {
        font-size: px(16);
        color: $colorGreen3;

        span {
          font-size: px(36);
          line-height: 1em;
          text-transform: none;
        }
      }

      &--turn {
        width: px(160);
        height: px(52);
        font-size: px(16);
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: px(1) solid $colorGreen5;
        background: $colorGreen4;
        box-shadow: 0 0 px(5) $colorGreen inset;
      }
    }
  }
}
