// Popup rules

.modal-content {
  .popup-rules {
    width: 100%;
    height: 100%;
    padding: px(43) px(80) 0;

    &__title {
      text-align: center;
      margin-bottom: px(40);

      img {
        height: px(60);
      }
    }

    &__content {
      height: px(630);
      font-size: px(20);
      font-family: $font-roboto;

      article {
        color: $colorGreen2;
        padding-right: px(25);

        &>* {
          margin-bottom: px(10) !important;
        }

        p {
          margin: 0;
        }

        ul {
          margin: 0;
          padding-left: px(40);
        }
      }
    }
  }
}
